import SQLPlayground from "./SQLPlayground";

import "./scss/style.scss";

function App() {
  return (
    <div className="App">
      <header>
        <img src="https://www.webaze.net/assets/images/logo-name.svg" alt="Webaze" />
      </header>
      <SQLPlayground />
    </div>
  );
}

export default App;
