import { useEffect, useRef, useState } from "react";
import initSqlJs from "sql.js";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";

function SQLPlayground() {

    const [db, setDb] = useState(null);
    const [err, setErr] = useState(null);
    const [results, setResults] = useState(null);
    const [queryInput, setQueryInput] = useState("");

    const [tables, setTables] = useState({});
    const [selectedTable, setSelectedTable] = useState(null);

    useEffect(() => {
        
        initSqlJs({
            locateFile: file => "https://globalstore.tubitor.com/ext/sqlpg/sql-wasm.wasm" 
        }).then(SQL => {
            setDb(new SQL.Database());
        }).catch(error => {
            setErr(error)
        });

        window.onbeforeunload = function () {
            return "Warning! When you leave the page, all your changes will be lost! Export the database before leaving.";
        }

    }, []);

    const execute = () => {
        setErr(null);
        setResults(null);
        try {
            var res = db.exec(queryInput);
            setResults(res);

            var tbls = {};
            var tableQuery = db.exec('SELECT tbl_name from sqlite_master WHERE type = "table"');
            if (tableQuery.length > 0) {
                tableQuery[0].values.map(item => {
                    tbls[item] = db.exec("SELECT * FROM " + item)[0];
                });
            }
            setTables(tbls);
            if (tbls.length > 0 && (!selectedTable || !tbls[selectedTable])) {
                setSelectedTable(Object.keys(tbls)[0]);
            }

        } catch (error) {
            setErr(error);
        }
    }

    return (<div id="sql-playground">

        <div className="editor">
            <div className="input">
                <AceEditor
                    mode="sql"
                    theme="github"
                    onChange={e => setQueryInput(e)}
                    name="sql-input"
                    fontSize={20}
                    showGutter={true}
                    highlightActiveLine={true}
                    width="100%"
                    height="calc(100% - 50px)"
                />
                <button onClick={_ => execute()} id="sql-execute">Execute</button>
            </div>
            <div className="output">

                <h2>Result:</h2>

                {err && <div className="error">{(err || "").toString()}</div>}

                {(results && results.length === 0) && <div className="success">Query executed successfull (result is empty).</div>}
                
                {(results && results.length > 0) && <div className="results_table">
                    {results.map(result => {
                        return <table style={{marginBottom: "1rem"}}>
                            <thead>
                                <tr>
                                    {result.columns.map(item => (
                                        <td>{item}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {result.values.map(item => (
                                    <tr>
                                        {item.map(row => (
                                            <td>{row}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    })}
                </div>}

            </div>
        </div>
        <div className="browser">

            <div className="links">
                Help:
                <a href="https://support.tubitor.com/knowledgebase.php?article=4" target="_blank">SQL - The basics</a>
                |
                <a href="https://support.tubitor.com/knowledgebase.php?article=5" target="_blank">SQL - Advanced</a>
            </div>

            <div className="actions">
                <button onClick={_ => {
                    console.log("Working...");
                    console.log(db.export());
                    var file = new Blob([db.export()], { type: "application/x-sqlite3" });
                    saveFile(file, "webaze-sql-" + (new Date().getUTCDate()) + "-" + (new Date().getUTCMonth()) + "-" + (new Date().getUTCFullYear()) + ".sql");
                }}>Export</button>
                <div className="input"><input type="file" onChange={e => {
                    var file = e.target.files[0];
                    if (file) {
                        var fileReader = new FileReader();
                        fileReader.onload = function () {
                            var arrayBuffer = this.result,
                                array = new Uint8Array(arrayBuffer);
                            initSqlJs({
                                locateFile: file => "https://globalstore.tubitor.com/ext/sqlpg/sql-wasm.wasm" 
                            }).then(SQL => {
                                var db = new SQL.Database(array)
                                setDb(db);

                                var tbls = {};
                                var tableQuery = db.exec('SELECT tbl_name from sqlite_master WHERE type = "table"');
                                if (tableQuery.length > 0) {
                                    tableQuery[0].values.map(item => {
                                        tbls[item] = db.exec("SELECT * FROM " + item)[0];
                                    });
                                }
                                setTables(tbls);
                                if (tbls.length > 0 && (!selectedTable || !tbls[selectedTable])) {
                                    setSelectedTable(Object.keys(tbls)[0]);
                                }
                            }).catch(error => {
                                setErr(error)
                            });
                        }
                        fileReader.readAsArrayBuffer(file);
                    }
                }} /></div>
            </div>

            {Object.keys(tables).length > 0 ? <>
            
                <ul>
                    {Object.keys(tables).map(table => (
                        <li className={(table === selectedTable ? "current" : "")} onClick={_ => setSelectedTable(table)}>{table}</li>
                    ))}
                </ul>

                {(selectedTable && tables[selectedTable]) && <div className="results_table">
                    <table>
                        <thead>
                            <tr>
                                {tables[selectedTable].columns.map(item => (
                                    <td>{item}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tables[selectedTable].values.map(item => (
                                <tr>
                                    {item.map(row => (
                                        <td>{row}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                
            </> : <><p>There are no databases to show.</p></>}

        </div>

    </div>)

}

export default SQLPlayground;

function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}

function base64ToUint8Array (string) {
  var raw = atob(string);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));
  for (var i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

function Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while(i < len) {
	c = array[i++];
	switch(c >> 4)
	{ 
	  case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
	    // 0xxxxxxx
	    out += String.fromCharCode(c);
	    break;
	  case 12: case 13:
	    // 110x xxxx   10xx xxxx
	    char2 = array[i++];
	    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
	    break;
	  case 14:
	    // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
	    char3 = array[i++];
	    out += String.fromCharCode(((c & 0x0F) << 12) |
					   ((char2 & 0x3F) << 6) |
					   ((char3 & 0x3F) << 0));
	    break;
	}
    }

    return out;
}